import { HamburgerIcon } from "../app/assets/HamburgerIcon";
import { Link } from 'react-router-dom';

export default function Header() {
    const handleClick = () => {
        const menu = document.getElementById('menu');
        if (!menu.hasAttribute('hidden')) {
            menu.classList.toggle('hidden');
        }
    }

    return (
        <header>
            <nav className="fixed w-full z-30 top-0 shadow-md bg-gray-500">
                <div className="container w-full mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                    <div className="flex pl-4 items-center">
                        <Link className="text-white font-bold text-2xl lg:text-4xl font-['Lobster']"
                            to="/">
                            Tomato Pie
                        </Link>
                    </div>
                    <div className="block lg:hidden pr-4">
                        <button className="flex items-center p-1" onClick={handleClick}>
                            <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        </button>
                    </div>
                    <div id="menu" className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 p-4 lg:p-0 z-20">
                        <ul className="flex-1 lg:flex justify-end items-center">
                            <li className="mr-3">
                                <Link className="inline-block py-2 px-4" to={"/"} onClick={handleClick}>
                                    Home
                                </Link>
                            </li>
                            <li className="mr-3">
                                <Link className="inline-block py-2 px-4" to={'/Menu'} onClick={handleClick}>
                                    Menu
                                </Link>
                            </li>
                            <li className="mr-3">
                                <Link className="inline-block py-2 px-4" to={"/Contact"} onClick={handleClick}>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
/*
<li className="mr-3">
    <Link className="inline-block py-2 px-4" to={"/About"} onClick={handleClick}>
        About
    </Link>
</li>
*/